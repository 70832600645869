/** eslint-disable */
import { useRoute } from 'vue-router'
import {
  ref,
  onMounted,
  getCurrentInstance,
  computed
} from 'vue'
import { refundUseCase } from '@/domain/usecase'
import { useStore } from 'vuex'
import moment from 'moment'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import currencyFormat from '@/utils/currencyFormat'

export default {
  setup() {
    const app = getCurrentInstance()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const store = useStore()
    const checked = ref(false)
    const data = ref([])
    const inputSearch = ref('') as any
    const dateOp = ref()
    // const dateFilter = ref(null)
    const dateFilter = ref(moment().format('YYYY-MM-DD')) as any
    const dateFilterTemporary = ref(null) as any
    /* eslint-disable-next-line */
    const dataIsCheck = computed(() => data.value.filter((x: any) => x.IsCheck === true)) as any
    const showModalConfirm = ref(false)
    const filters = {
      search: '',
      date: '',
      orderBy: 'CreatedAt desc',
      filter: "PaymentStatus eq 'unpaid'",
      expand: "&$expand=TransPengirimanHd($expand=JenisPembayaran($filter=Kode in ('GOPAY')))",
      // select: '&$select=Id, PelangganId, ManifestId, TransJadwalHdId, JenisPembayaranId, TransPenerimaanId, AgenAsalId, AgenTujuanId, Resi',
      totalRecords: 0,
      top: 0,
      skip: 0
    }

    const getAllData = async () => {
      try {
        store.dispatch('showLoading')
        const response = await refundUseCase.getAll(filters)
        // console.log('response', response)
        data.value = response.result.map((x: any) => {
          // is check digunakan untuk handling checking pada view
          x.IsCheck = false
          return x
        })
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error', error.response)
        store.dispatch('hideLoading')
      }
    }

    const onConfirm = async (value: any) => {
      try {
        console.log('onClick Confirm', value)
        store.dispatch('showLoading')
        const payload = {
          Id: value.Id,
          IsConfirmed: true,
          RefundNominal: value.RefundNominal,
          Notes: ''
        }
        const response = await refundUseCase.confirmTransPayoutRefund(payload)
        console.log('on response', response)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        } else {
          value.IsConfirmed = true
        }
        store.dispatch('hideLoading')
      } catch (error) {
        console.log('error', error.response)
      }
    }

    const processRefund = () => {
      console.log('masuk clicked onRefund')
      showModalConfirm.value = true
    }

    const onPayRefund = async () => {
      try {
        store.dispatch('showLoading')
        const payload = dataIsCheck.value.map((x: any) => ({
          Id: x.Id
        }))
        const response = await refundUseCase.approveTransPayoutRefund(payload)
        console.log('response after pay', response)
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        } else {
          $toast.add({
            severity: 'success', summary: 'Proses Refund masuk dashboard Midtrans', detail: 'Lanjutkan pembayaran refund pada dashboard Midtrans', group: 'bc', life: 1500
          })
        }
        showModalConfirm.value = false
        store.dispatch('hideLoading')
        getAllData()
      } catch (error) {
        console.log('error', error.response)
      }
    }

    const submitSearch = (val: any) => {
      // console.log('val', val)
      filters.search = val
      getAllData()
    }

    const showDateFilter = (evt: any) => {
      // console.log(evt)
      dateOp.value.toggle(evt)
    }

    const saveFilterDate = (val: any) => {
      // console.log('val', dateFilter.value)
      // filters.date = moment(dateFilter.value).format('YYYY-MM-DD')
      // eslint-disable-next-line no-use-before-define
      filters.date = filterTanggal()
      // console.log('filterDate', filters.date)
      getAllData()
      dateOp.value.hide()
    }

    const clearFilterDate = () => {
      dateFilter.value = null
      filters.date = moment().format('YYYY-MM-DD')
      getAllData()
      dateOp.value.hide()
    }

    const filterTanggal = () => {
      dateFilterTemporary.value = dateFilter.value
      if (!Array.isArray(dateFilterTemporary.value)) {
        dateFilterTemporary.value = [moment(), moment()]
      }
      if (dateFilterTemporary.value[1] === null) {
        // eslint-disable-next-line prefer-destructuring
        dateFilterTemporary.value[1] = dateFilterTemporary.value[0]
      }
      // console.log('dateFilterTemporary', dateFilterTemporary.value)
      return `CreatedAt ge ${moment(dateFilterTemporary.value[0]).format('YYYY-MM-DD')} and CreatedAt le ${moment(dateFilterTemporary.value[1]).format('YYYY-MM-DD')} `
    }

    onMounted(() => {
      getAllData()
    })

    return {
      checked,
      data,
      onConfirm,
      processRefund,
      dataIsCheck,
      showModalConfirm,
      onPayRefund,
      submitSearch,
      showDateFilter,
      clearFilterDate,
      saveFilterDate,
      inputSearch,
      dateOp,
      dateFilter,
      capitalizeFirstLetter,
      currencyFormat
    }
  }
}
