
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import TabsNavRefund from '@/views/transactions/delivery-item/refund/components/TabsNavRefund.vue'
import WaitingRefund from '@/views/transactions/delivery-item/refund/waiting-refund/WaitingRefund.vue'
import ProcessRefund from '@/views/transactions/delivery-item/refund/processed-refund/ProcessedRefund.vue'
import { useRoute } from 'vue-router'

export default {
  components: {
    TabsNavRefund,
    WaitingRefund,
    ProcessRefund
  },

  setup() {
    const route = useRoute() as any
    // eslint-disable-next-line arrow-body-style
    const checkFirstPosition = () => {
      // if (route.meta.permission.company.read && !route.meta.permission.retail.read) {
      //   return 'customerCompany'
      // }
      // return 'customerRetail'
      return 0
    }
    const positionTabs = ref(checkFirstPosition())
    const tabsChange = (val: any) => {
      positionTabs.value = val
      // console.log('masuk change', val)
    }
    return {
      tabsChange,
      route,
      positionTabs
    }
  }
}
