import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-6" }
const _hoisted_2 = { class: "col-end-7 col-span-2 mb-5" }
const _hoisted_3 = { class: "font-semibold" }
const _hoisted_4 = { class: "text-sm color-primary font-semibold" }
const _hoisted_5 = { class: "font-semibold" }
const _hoisted_6 = { class: "font-semibold color-blue-grey text-sm" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIconRightGrey = _resolveComponent("InputIconRightGrey")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Paginator = _resolveComponent("Paginator")

  return (_openBlock(), _createBlock("section", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_InputIconRightGrey, {
          placeholder: "Cari Disini ...",
          modelValue: _ctx.inputSearch,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputSearch = $event)),
          canClick: true,
          onSubmitInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitSearch(_ctx.inputSearch))),
          onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.submitSearch(_ctx.inputSearch)), ["enter"])),
          type: _ctx.$enumBase.input.text,
          icon: _ctx.$icon.svg.searchEva
        }, null, 8, ["modelValue", "type", "icon"])
      ])
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.data,
      paginator: false,
      rows: _ctx.filters.top,
      class: "table-og",
      responsiveLayout: "scroll"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, { header: "Nomor Resi" }, {
          body: _withCtx((slotProps) => [
            _createVNode("p", _hoisted_3, _toDisplayString(slotProps.data.TransPengirimanHd?.Resi), 1),
            _createVNode("p", _hoisted_4, _toDisplayString(slotProps.data.TransPengirimanHd?.NamaPengirim), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Tujuan" }, {
          body: _withCtx((slotProps) => [
            _createVNode("p", _hoisted_5, _toDisplayString(slotProps.data.PhoneNumber), 1),
            _createVNode("p", _hoisted_6, _toDisplayString(slotProps.data.TransPengirimanHd?.JenisPembayaran?.Nama), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Total Refund" }, {
          body: _withCtx((slotProps) => [
            _createVNode("p", _hoisted_7, "Rp " + _toDisplayString(_ctx.currencyFormat(slotProps.data.PayoutNominal)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Terbayarkan" }, {
          body: _withCtx((slotProps) => [
            _createVNode("p", _hoisted_8, "Rp " + _toDisplayString(_ctx.currencyFormat(slotProps.data.RefundNominal)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Status" }, {
          body: _withCtx((slotProps) => [
            _createVNode("div", {
              class: ["px-3 py-2 rounded-md", _ctx.classNameStatus(slotProps.data.PaymentStatus)],
              style: {"width":"fit-content"}
            }, _toDisplayString(_ctx.capitalizeFirstLetter(slotProps.data.PaymentStatus)), 3)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "rows"]),
    (_ctx.data.length > 0)
      ? (_openBlock(), _createBlock(_component_Paginator, {
          key: 0,
          rows: _ctx.filters.top,
          totalRecords: _ctx.filters.totalRecords,
          onPage: _ctx.onPage
        }, null, 8, ["rows", "totalRecords", "onPage"]))
      : _createCommentVNode("", true)
  ]))
}