
import {
  ref,
  watch
} from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'TabsNavRefund',
  emits: ['tabsChange'],
  // eslint-disable-next-line
  setup(props: any, { emit }: any) {
    const route = useRoute() as any
    const active = ref(0)

    // eslint-disable-next-line arrow-body-style
    const generateHeader = () => {
      // if (route.meta.permission.retail.read && route.meta.permission.company.read) {
      //   return [{ title: 'Pelanggan Retail', value: 'customerRetail' }, { title: 'Pelanggan Perusahaan', value: 'customerCompany' }]
      // }
      return [{ title: 'Menunggu', value: 0 }, { title: 'Diproses', value: 1 }]
    }

    const arrHeader = ref(generateHeader()) as any

    watch(active, (newVal, oldVal) => {
      // console.log('new Val', newVal)
      if (newVal !== oldVal) {
        emit('tabsChange', arrHeader.value[newVal].value)
      }
    })

    return {
      active,
      arrHeader,
      route
    }
  }

}
