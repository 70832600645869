import { useRoute } from 'vue-router'
import {
  ref, onMounted, watch, getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'
import { refundUseCase } from '@/domain/usecase'
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'
import currencyFormat from '@/utils/currencyFormat'

export default {
  name: 'ProcessRefund',
  setup() {
    const app = getCurrentInstance()
    const store = useStore()
    const { $confirm, $toast, $strInd } = app!.appContext.config.globalProperties
    const inputSearch = ref('') as any
    // const value1 = ref(0)
    const checked = ref(false)
    const data = ref([])

    const filters = {
      search: '',
      expand: "&$expand=TransPengirimanHd($expand=JenisPembayaran($filter=Kode in ('GOPAY')))",
      filter: "PaymentStatus in ('queued', 'failed', 'completed')",
      orderBy: 'CreatedAt desc',
      totalRecords: 0,
      top: 10,
      skip: 0
    }

    const getAllData = async () => {
      store.dispatch('showLoading')
      // filters.search = inputSearch.value
      const response = await refundUseCase.getAll(filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: $strInd.toast.errorAllData, group: 'bc', life: 1500
        })
      } else {
        filters.totalRecords = response.count
        data.value = response.result.map((x: any) => {
          x.Resi = x.TransPengirimanHd.Resi
          return x
        })
      }
      store.dispatch('hideLoading')
    }

    const submitSearch = (val: any) => {
      filters.search = val
      getAllData()
    }

    const onPage = (event: any) => {
      filters.skip = event.page * filters.top
      getAllData()
    }

    const classNameStatus = (nameStatus: any) => {
      console.log('mamm', nameStatus)
      let classReturn = 'label-green-lighten-5'
      if (nameStatus === 'failed') {
        classReturn = 'label-red-lighten-5'
      } else if (nameStatus === 'queued') {
        classReturn = 'label-orange-lighten-5'
      }
      return classReturn
    }

    onMounted(() => {
      getAllData()
    })

    return {
      data,
      submitSearch,
      inputSearch,
      capitalizeFirstLetter,
      currencyFormat,
      classNameStatus,
      onPage,
      filters
    }
  }
}
